import * as d3 from 'd3'
import {CharInfo, TextAttribute, TextSection} from './types'

export class Vectorizer {

    public wordIndex: number
    private lineIndex: number
    public linePosition: number
    charInWordPosition: number
    wordIndexLine: number
    charCount: Record<string, number>

    defaultClickHandlers = {
        click: (d, svg) => {},
        mouseover: (d, svg) => {},
        mouseout: (d, svg) => {}
    }

    constructor() {
        this.wordIndex = 0
        this.lineIndex = 0
        this.linePosition = -1
        this.charInWordPosition = -1
        this.wordIndexLine = 0
        this.charCount = {}
    }

    public resetIndexes() {
        this.wordIndex = 0
        this.lineIndex = 0
        this.linePosition = -1
        this.charInWordPosition = -1
        this.wordIndexLine = 0
    }

    public resetDictionary() {
        this.charCount = {}
    }

    private vectorize(input: string, maxChars?: number): CharInfo[] {
        let chars = input.split('')
        let words = input.split(' ')
        let result = chars.map((char, i) => {
            this.linePosition++
            this.charInWordPosition++
            if(char in this.charCount) {
                this.charCount[char]++
            }
            else this.charCount[char] = 0
            if(char === ' ') {
                this.wordIndex++
                this.charInWordPosition = -1
                if(maxChars && this.wordIndex < words.length && this.linePosition + words[this.wordIndex].length + this.wordIndexLine > maxChars) {
                    this.wordIndexLine = 0
                    this.lineIndex++
                    this.linePosition = -1
                }
            }
            return {
                char,
                absolutePosition: i,
                occurence: this.charCount[char],
                wordIndex: this.wordIndex,
                word: words[this.wordIndex],
                lineIndex: this.linePosition === -1 ? this.lineIndex - 1 : this.lineIndex,
                linePosition: this.linePosition,
                wordPosition: this.charInWordPosition,
                wordIndexLine: this.wordIndexLine,
                originX: 0,
                originY: 0,
                charSize: 10,
                charWidth: 7,
                lineSpacing: 20,
                charColor: 'black',
                on: this.defaultClickHandlers
            }
        })
        return result
    }

    private fillMatrix = (data: CharInfo[], width: number, position: 'middle' | 'left' | 'right', fill: string) => {
        const numLines = data.reduce((max, d) => d.lineIndex > max ? d.lineIndex : max, 0)
        let result: CharInfo[] = []
        for(let i = 0; i <= numLines; i++) {
            const line = data.filter(datum => datum.lineIndex === i)
            for(let j = 0; j <= width; j++) {
                let isOutside: boolean = false
                if(
                    position === 'left' &&
                    j >= line.length
                ) isOutside = true
                else if(position === 'left') result.push(line[j])
                else if(
                    position === 'right' &&
                    width - j + 1 >= line.length
                ) isOutside = true
                else if(position === 'right') {
                    const element: CharInfo = line[j - (width - line.length + 2)]
                    element.linePosition = j - 1
                    result.push(element)
                }
                else if(position === 'middle') {
                    const startIndex = Math.floor((line.length - width) / 2)
                    if(startIndex + j - 1 < 0 || startIndex + j > line.length - 1) isOutside = true
                    else {
                        const element: CharInfo = line[startIndex + j - 1]
                        element.linePosition = line.length % 2 === 0 ? j - 1 : j - 0.5
                        result.push(element)
                    }
                }
                if(fill && isOutside) {
                    if(fill in this.charCount) {
                        this.charCount[fill]++
                    }
                    else this.charCount[fill] = 0
                    result.push({
                        char: fill,
                        lineIndex: i,
                        linePosition: j - 1,
                        originX: 0,
                        originY: 0,
                        charSize: 10,
                        charWidth: 7,
                        lineSpacing: 20,
                        charColor: 'black',
                        occurence: this.charCount[fill],
                        on: this.defaultClickHandlers
                    })
                }
            }
        }
        return result
    }

    private setAttribute = (
        data: CharInfo[],
        attribute: TextAttribute,
        section: TextSection
    ) => {
        const start = attribute.startIndex && attribute.startIndex >= 0 ? attribute.startIndex : 0;
        const end = attribute.endIndex && attribute.endIndex < data.length ? attribute.endIndex : data.length;
        for(let i = start; i < end; i++) {
            let result = attribute.value
            if(attribute.attribute === "click") {
                // @ts-ignore
                data[i].on.click = attribute.value
            } else if(attribute.attribute === "mouseover") {
                // @ts-ignore
                data[i].on.mouseover = attribute.value
            } else if(attribute.attribute === "mouseout") {
                // @ts-ignore
                data[i].on.mouseout = attribute.value
            } else if(typeof result === "function") {
                // @ts-ignore
                result = attribute.value(
                    data,
                    section.charWidth,
                    section.charSize,
                    section.maxChars,
                    section.lineSpacing
                )
            }
            data[i][attribute.attribute] = result
        }
        return data
    }

    public drawCharGrid = (inputData: CharInfo[], svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>) => {

        const getX = (char: CharInfo, i: number) => char.linePosition * char.charWidth + Math.random() / 2 + char.originX

        const getY = (char: CharInfo) => char.lineIndex * char.lineSpacing + Math.random() / 2 + char.originY

        const getRotation = (char: CharInfo, i: number) => "rotate(" + (Math.random() - .5) * 10 + "," + getX(char, i) + "," + getY(char) + ")"

        const getTransform = (char: CharInfo, i: number) => getRotation(char, i) + "translate(" + getX(char, i) + "," + getY(char) + ")"

        const getPreTransform = (char: CharInfo, i: number) => getRotation(char, i) + "translate(" + getX(char, i) + "," + (getY(char) + 150) + ")"

        const getPostTransform = (char: CharInfo, i: number) => getRotation(char, i) + "translate(" + getX(char, i) + "," + (getY(char) + char.lineSpacing * 2) + ")"

        const getDelay = (char: CharInfo, i: number) => i + Math.random() * 400

        const t = svg.transition()
            .duration(1000)
            .ease(d3.easeBackInOut)

        svg
            .attr("font-family", "Special Elite")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .selectAll("text")
            .data(inputData, (d: CharInfo) => d.char + d.occurence)
            .join(
                enter => enter.append("text")
                    .on('click', (d) => d.on.click(d, svg))
                    .on('mouseover', (d) => d.on.mouseover(d, svg))
                    .on('mouseout', (d) => d.on.mouseout(d, svg))
                    .attr("opacity", "0")
                    .attr("fill", (d) => d.charColor)
                    .attr("z-index", 1)
                    .attr("font-size", (d) => d.charSize)
                    .attr("transform", (d, i) => getPreTransform(d, i))
                    .text(d => d.char)
                    .call(enter => enter.transition(t)
                        .delay((d, i) => getDelay(d, i))
                        .attr("opacity", "1")
                        .attr("z-index", 9999)
                        .attr("transform", (d, i) => getTransform(d, i))
                    ),
                update => update
                    .on('click', (d) => d.on.click(d, svg))
                    .on('mouseover', (d) => d.on.mouseover(d, svg))
                    .on('mouseout', (d) => d.on.mouseout(d, svg))
                    .call(update => update.transition(t)
                        .delay((d, i) => getDelay(d, i))
                        .attr("transform", (d, i) => getTransform(d, i))
                        .attr("font-size", (d) => d.charSize)
                        .attr("opacity", "1")
                        .attr("fill", (d) => d.charColor)
                        .attr("z-index", 9999)
                    ),
                exit => exit
                    .call(exit => exit
                        .attr("z-index", 1)
                        .transition(t)
                        .attr("transform", (d, i) => getPostTransform(d, i))
                        .attr("opacity", "0")
                        .remove()
                    )
            );
    }

    public computeTextSection(section: TextSection): CharInfo[] {
        let text = this.vectorize(section.text, section.maxChars)
        section.attributes.forEach((attribute: TextAttribute) => {
            text = this.setAttribute(text, attribute, section)
        })
        if(section.charWidth)
            text = this.setAttribute(text, {attribute: "charWidth", value: section.charWidth}, section)
        if(section.charSize)
            text = this.setAttribute(text, {attribute: "charSize", value: section.charSize}, section)
        if(section.lineSpacing)
            text = this.setAttribute(text, {attribute: "lineSpacing", value: section.lineSpacing}, section)
        if(section.align)
            text = this.fillMatrix(text, section.maxChars, section.align, '')
        return text
    }

}
