import * as d3 from 'd3'
import {Vectorizer} from './string_vectorizer'
import {Page, TextSection, CharInfo, TextAttribute} from './types'
import { desktopPages } from './pages/desktopPages'

export const drawDesktopMenu = (selectedPageIndex: number, updatePage, selectedMenuItemColor: string, unSelectedMenuItemColor: string, vectorizer: Vectorizer) => {

    const result = desktopPages.reduce((arr, page, pageIndex) => {
        let charSize = 20
        let charColor = unSelectedMenuItemColor
        if(pageIndex === selectedPageIndex) {
            charSize *= 1.4
            charColor = selectedMenuItemColor
        }
        const section: TextSection = {
            text: page.shortDescription,
            charSize: charSize,
            charWidth: 17,
            attributes: [
                {
                    attribute: 'originX', value: (text, cW, cS, mC, lS) =>
                        window.innerWidth / (desktopPages.length + 2) * (pageIndex + 1) - (text.length) * (cW + cS) / 2
                },
                {attribute: 'originY', value: 50},
                {attribute: 'charColor', value: charColor},
                {
                    attribute: 'click',
                    value: (d, svg) => {
                        updatePage(d.data)
                    },
                    startIndex: 0,
                    endIndex: page.shortDescription.length
                },
                {
                    attribute: 'mouseover',
                    value: (d, svg) => {
                        svg.style('cursor', 'pointer')
                    },
                    startIndex: 0,
                    endIndex: page.shortDescription.length
                },
                {
                    attribute: 'mouseout',
                    value: (d, svg) => {
                        svg.style('cursor', 'default')
                    },
                    startIndex: 0,
                    endIndex: page.shortDescription.length
                },
                {attribute: 'data', value: pageIndex}
            ]
        }
        const charInfoArr = vectorizer.computeTextSection(section)
        return arr.concat(charInfoArr)
    }, new Array<CharInfo>())

    d3.select('title').text(desktopPages[selectedPageIndex].title.text)

    return result

}

export const drawMobileMenu = (selectedPageIndex: number, updatePage, selectedMenuItemColor: string, unSelectedMenuItemColor: string, vectorizer: Vectorizer) => {

    const result = desktopPages.reduce((arr, page, pageIndex) => {
        let charSize = 45
        let charColor = unSelectedMenuItemColor
        if (pageIndex === selectedPageIndex) {
            charSize *= 1.4
            charColor = selectedMenuItemColor
        }
        const section: TextSection = {
            text: page.shortDescription,
            charSize: charSize,
            charWidth: 31,
            attributes: [
                {
                    attribute: 'originX', value: (text, cW, cS, mC, lS) =>
                        -620 + pageIndex * 37
                },
                { attribute: 'originY', value: 50 },
                { attribute: 'charColor', value: charColor },
                {
                    attribute: 'click', value: (d, svg) => {
                        updatePage(d.data)
                    },
                    startIndex: 0,
                    endIndex: page.shortDescription.length
                },
                {
                    attribute: 'mouseover', value: (d, svg) => {
                        svg.style('cursor', 'pointer')
                    },
                    startIndex: 0,
                    endIndex: page.shortDescription.length
                },
                {
                    attribute: 'mouseout', value: (d, svg) => {
                        svg.style('cursor', 'default')
                    },
                    startIndex: 0,
                    endIndex: page.shortDescription.length
                }, 
                { attribute: 'data', value: pageIndex }
            ]
        }
        const charInfoArr = vectorizer.computeTextSection(section)
        return arr.concat(charInfoArr)
    }, new Array<CharInfo>())

    return result

}
