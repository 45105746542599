import * as d3 from 'd3'
import {Vectorizer} from './string_vectorizer'
import {Page, TextSection, CharInfo, TextAttribute} from './types'
import { drawDesktopMenu, drawMobileMenu } from './menu_drawer';
import { desktopPages } from './pages/desktopPages';
import { mobilePages } from './pages/mobilePages';

export class PageDrawer {

    private pageIndex: number = 0
    private svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>
    private vectorizer: Vectorizer

    constructor(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>) {
        this.svg = svg;
        this.pageIndex = 0
    }

    private updatePage = (newIndex) => {
        this.pageIndex = newIndex
        this.draw(this.pageIndex)
    }

    private redraw = () => {
        this.draw(this.pageIndex)
    }

    public draw(pageIndex: number) {

        this.vectorizer = new Vectorizer()

        // todo
        //window.addEventListener("resize", this.redraw);

        this.pageIndex = pageIndex
        let page = desktopPages[this.pageIndex]

        let result: CharInfo[] = []

        // add desktop menu
        result = drawDesktopMenu(pageIndex, this.updatePage, page.selectedMenuItemColor, page.unSelectedMenuItemColor, this.vectorizer)

        d3.select('svg')
            .attr('viewBox', null)
            .attr("width", window.innerWidth - 20)
            .attr("height", window.innerHeight - 20)
        
        // @ts-ignore
        if (window.innerWidth < 800) {// && window.innerWidth < 800) {
            console.log('Mobile', window.innerWidth)

            d3.select('svg')
                .attr('viewBox', "0 0 800 800")
                .attr('width', null)
                .attr('height', null)

            // add desktop menu
            result = drawMobileMenu(pageIndex, this.updatePage, page.selectedMenuItemColor, page.unSelectedMenuItemColor, this.vectorizer)

            page = mobilePages[this.pageIndex]
        }

        this.vectorizer.resetIndexes()

        // calculate title
        const title = this.vectorizer.computeTextSection(page.title)
        result = result.concat(title)

        this.vectorizer.resetIndexes()

        // calculate content
        const content = this.vectorizer.computeTextSection(page.content)
        result = result.concat(content)

        this.vectorizer.drawCharGrid(result, this.svg)

        d3.select('body')
            .transition()
            .duration(1000)
            .style("background-color", page.backgroundColor)

        d3.selectAll('.showcase').remove()

        const showcase = d3.select('#showcase')

        if(page.showCase) {

            page.showCase(showcase)
        }

    }

}
