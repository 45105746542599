import * as d3 from 'd3'
import { Page } from '../types'

const fullTitleHeight = 400
const fullContentHeight = 500
const partialTitleHeight = 200
const partialContentHeight = 300
const partialTileContentHeight = window.innerWidth / 800 * 490

const frameHeight = 280
const frameWidth = 380

export const mobilePages: Page[] = [
    {
        title: {
            text: "Franz Flückiger's Page",
            charSize: 60,
            charWidth: 27,
            attributes: [
                { attribute: 'originX', value: 100 },
                { attribute: 'originY', value: fullTitleHeight},
                {attribute: 'charColor', value: 'rgb(98,11,19)'},
                {attribute: 'charColor', value: 'rgb(144,33,42)', startIndex: 18, endIndex: 27}
            ]
        },
        shortDescription: 'Home',
        content: {
            text: "My name is Franz Flückiger, I'm a Musician and Software Engineer based in Winterthur. ",
            charSize: 34,
            charWidth: 22,
            lineSpacing: 70,
            maxChars: 27,
            attributes: [
                {attribute: 'originX', value: 100},
                { attribute: 'originY', value: fullContentHeight},
                {attribute: 'charColor', value: 'rgb(98,11,19)'},
                {attribute: 'charColor', value: 'rgb(144,33,42)', startIndex: 34, endIndex: 42},
                {attribute: 'charColor', value: 'rgb(144,33,42)', startIndex: 47, endIndex: 64}
            ],
            align: "middle"
        },
        backgroundColor: 'rgb(252, 240, 202)',
        selectedMenuItemColor: 'rgb(226, 188, 136)',
        unSelectedMenuItemColor: 'rgb(98,11,19)',
    },




    {
        title: {
            text: "Franz Flückiger's Music",
            charSize: 60,
            charWidth: 27,
            attributes: [
                { attribute: 'originX', value: 100 },
                { attribute: 'originY', value: partialTitleHeight },
                {attribute: 'charColor', value: '#03254c'},
                {attribute: 'charColor', value: 'rgb(253,123,95)', startIndex: 18, endIndex: 27}
            ]
        },
        shortDescription: 'Music',
        content: {
            text: "As a musician, I had the pleasure to play with many talented musicians.",
            charSize: 34,
            charWidth: 22,
            lineSpacing: 70,
            maxChars: 27,
            attributes: [
                {attribute: 'originX', value: 90},
                { attribute: 'originY', value: partialContentHeight},
                {attribute: 'charColor', value: '#03254c'},
                {attribute: 'charColor', value: '#1167b1', startIndex: 56, endIndex: 65}
            ],
            align: "left"
        },
        backgroundColor: '#d0efff',
        showCase: (selection) => {

            const songArray = ['EOS2mb-Xk4c', 'md_2eycITYU', '9Pbhn3CVAIg', 'I98zvPTW5u4', 'pYbDS1b7VAg', 'IglpCmJqKwk']

            setTimeout(() =>
                selection
                    .data(songArray)
                    .join(
                        enter => {

                            let sel = enter.append('div')
                                .attr('class', 'showcase')
                                .style('position', 'absolute')
                                .style('top', (d, i) => partialTileContentHeight + i * (frameHeight + 10))
                                .style('right', '-800px')

                            sel
                                .append('iframe')
                                .attr('class', 'showcase')
                                .style('width', frameWidth)
                                .style('height', frameHeight)
                                .attr('src', (d) => 'https://www.youtube-nocookie.com/embed/' + d)
                                .attr('frameborder', "0")
                                .attr('allow', "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;")
                                .attr('allowfullscreen', "allowfullscreen")
                                .attr('allowfullscreen', "true")

                            sel
                                .transition()
                                .duration(100)
                                .transition()
                                .duration(1000)
                                .ease(d3.easeBackInOut)
                                .delay((d, i) => i * 100)
                                .style('right', (d, i) => window.innerWidth / 2 - frameWidth / 2 - 10 + 'px')

                            return enter
                        }
                    ), 1500
            )

            return selection
        },
        selectedMenuItemColor: '#03254c',
        unSelectedMenuItemColor: '#1167b1',
    },




    {
        title: {
            text: "Franz Flückiger's Codes",
            charSize: 60,
            charWidth: 27,
            attributes: [
                { attribute: 'originX', value: 100 },
                { attribute: 'originY', value: partialTitleHeight },
                {attribute: 'charColor', value: '#7FFF00'},
                {attribute: 'charColor', value: 'white', startIndex: 18, endIndex: 27}
            ]
        },
        shortDescription: 'Codes',
        content: {
            text: "I like to explore the boundaries between data visualization and digital art.",
            charSize: 34,
            charWidth: 22,
            lineSpacing: 70,
            maxChars: 30,
            attributes: [
                {attribute: 'originX', value: 65},
                {attribute: 'originY', value: partialContentHeight},
                {attribute: 'charColor', value: '#7FFF00'},
                {attribute: 'charColor', value: 'white', startIndex: 41, endIndex: 59},
                {attribute: 'charColor', value: 'white', startIndex: 64}
            ],
            align: "right"
        },
        backgroundColor: 'black',
        showCase: (selection) => {

            d3.select('body')
                .append('div')
                .attr('class', 'showcase')
                .attr('id', 'showcase')

            const codeArray = [
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail826812@2x.jpg',
                    desc: 'Helmholtz decomposition',
                    url: 'https://www.openprocessing.org/sketch/826812'
                },
                {
                    dir: 'https://raw.githubusercontent.com/FranzFlueckiger/storygram-explorer/master/public/storyGram_Blockbuster.jpg',
                    desc: 'The Storygram',
                    url: 'https://storygram-explorer.netlify.app/'
                },
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail820045@2x.jpg',
                    desc: 'Cluster wars',
                    url: 'https://www.openprocessing.org/sketch/820045'
                },
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail820428@2x.jpg',
                    desc: 'Multiclass Wobbly Swarm',
                    url: 'https://www.openprocessing.org/sketch/820428'
                },
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail828987@2x.jpg',
                    desc: 'Wobbly Lens',
                    url: 'https://www.openprocessing.org/sketch/828987'
                },
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail844097@2x.jpg',
                    desc: 'Breathing Mandelbrot',
                    url: 'https://www.openprocessing.org/sketch/844097'
                },
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail825476@2x.jpg',
                    desc: 'PageRank Algorithm',
                    url: 'https://www.openprocessing.org/sketch/825476'
                },
                {
                    dir: 'https://openprocessing-usercontent.s3.amazonaws.com/thumbnails/visualThumbnail820312@2x.jpg',
                    desc: 'Smoke Curve',
                    url: 'https://www.openprocessing.org/sketch/820312'
                },
            ]
            setTimeout(() =>
                selection
                    .data(codeArray)
                    .join(
                        enter => {

                            let sel = enter.insert('div')
                                .attr('class', 'showcase')
                                .style('position', 'absolute')
                                .style('top', (d, i) => partialTileContentHeight + i * (frameHeight + 10))
                                .style('left', '-800px')
                                .style('width', frameWidth + 'px')
                                .style('height', frameHeight + 'px')
                                .style('text-align', 'center')

                            sel
                                .append('a')
                                .attr('class', 'showcase')
                                .attr('href', (d) => d.url)
                                .attr('target', 'blank')
                                .append('img')
                                .attr('object-fit', 'contain')
                                .attr('src', (d) => d.dir)
                                .style('max-width', '100%')
                                .style('max-height', '100%')
                                .style('width', 'auto')
                                .style('height', frameHeight + 'px')
                            
                            sel
                                .transition()
                                .duration(100)
                                .transition()
                                .duration(1000)
                                .ease(d3.easeBackInOut)
                                .delay((d, i) => i * 100)
                                .style('left', (d, i) => window.innerWidth / 2 - frameWidth / 2 - 10 + 'px')

                            return enter
                        }
                    ), 1100
            )

        },
        selectedMenuItemColor: 'white',
        unSelectedMenuItemColor: 'green',
    },




    {
        title: {
            text: "Contact",
            charSize: 60,
            charWidth: 27,
            attributes: [
                { attribute: 'originX', value: 300 },
                { attribute: 'originY', value: fullTitleHeight },
                {attribute: 'charColor', value: '#FFCE2B'}
            ]
        },
        shortDescription: 'Contact',
        content: {
            text: "Let's get in touch! Write to: thecescobauer at hotmail dot com ",
            charSize: 34,
            charWidth: 22,
            lineSpacing: 70,
            maxChars: 27,
            attributes: [
                {attribute: 'originX', value: 110},
                {attribute: 'originY', value: fullContentHeight},
                {attribute: 'charColor', value: '#B2AFAC'},
                {attribute: 'charColor', value: '#FFCE2B', startIndex: 30}
            ],
            align: "middle"
        },
        backgroundColor: '#42413D',
        selectedMenuItemColor: '#B2AFAC',
        unSelectedMenuItemColor: '#959492',
    }
]
